import React, {useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import DarkSideButton from "../../Components/UI/DarkSideButton";
import logo from '../../assets/Logo.png'
import fig from '../../assets/doki_1.png'


const Login = () => {

    const {login} = useAuth();
    const [password, setPassword] = useState('');
    const secretPW = '2022';
    const [error, setError] = useState({text: '', isVisible: false});
    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }
    const handleLogin = () => {
        if (password === secretPW) {
            login({data: password})
        } else {
            setError({text: 'Helytelen jelszó!', isVisible: true});
        }

    }

    return (
        <div
            className={"flex flex-col items-center bg-primary justify-center min-h-screen overflow-hidden dark:bg-black dark:text-white"}>
            <div className="absolute top-5 right-5 flex items-center justify-center">
                <DarkSideButton/>
            </div>

            <div className=" w-1/2 lg:w-1/4 text-center relative">
                <img src={logo} alt="" className={'pb-40'}/>
                <div className={'mb-8 text-white font-semibold'}>Üdvözöljük az Accord standján,
                    kérem adja meg jelszavát!
                </div>
                <form onSubmit={handleLogin}>
                    <input type="password" value={password} onChange={handlePasswordChange} id={'password'}
                           className={' text-white'}/>
                    <button
                        className={"btn-primary"}>Bejelentkezés
                    </button>
                </form>

                <div className="absolute bottom-0 -translate-x-full translate-y-[40%]"><img src={fig} alt=""/></div>
                {error.isVisible &&
                    <div className="text-red-500">{error.text}</div>}
            </div>

        </div>
    );
};

export default Login;
