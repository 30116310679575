import React, {useState} from 'react';
import DarkSideButton from "../../Components/UI/DarkSideButton";
import video from "../../assets/videos/video.mp4";
import {NavLink} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import bg from "../../assets/paint.png";
import logo from "../../assets/Logo.png";
import pushing from "../../assets/pushing.png";
import pushing2 from "../../assets/pushing-2.png";
import laptop from "../../assets/laptop.png";


const NotFound = () => {
    const {logout} = useAuth()
    return (<>
        <div className="absolute top-5 right-5 flex md:hidden xl:flex z-30 gap-3 items-center justify-center">
            <DarkSideButton/>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 47.917 52.198"
                 onClick={logout}
                 className={'stroke-slate-400 dark:stroke-primary'}>
                <g id="Component_27_3" data-name="Component 27 – 3" transform="translate(4)">
                    <g id="Group_32" data-name="Group 32" transform="translate(-269.328 -222.701)">
                        <path id="Path_10" data-name="Path 10" d="M233.27,318.105a19.958,19.958,0,1,0,23.131-1.051"
                              transform="translate(43.714 -82.881)" fill="none" strokeMiterlimit="10"
                              strokeWidth="8"/>
                        <line id="Line_2" data-name="Line 2" y2="25.128" transform="translate(288.467 222.701)"
                              fill="none" strokeMiterlimit="10" strokeWidth="8"/>
                    </g>
                </g>
            </svg>

        </div>
        <div className={'h-screen flex flex-col text-center overflow-hidden hidden md:flex xl:hidden'}>
            <div className={'flex items-center w-full bg-[#E2ECE4] h-full justify-center'}>
                <div className="absolute z-20 top-5 flex  gap-3 items-center justify-center right-5 ">
                    <DarkSideButton/>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 47.917 52.198"
                         className={'stroke-slate-400 dark:stroke-primary'}
                         onClick={logout}>
                        <g id="Component_27_3" data-name="Component 27 – 3" transform="translate(4)">
                            <g id="Group_32" data-name="Group 32" transform="translate(-269.328 -222.701)">
                                <path id="Path_10" data-name="Path 10"
                                      d="M233.27,318.105a19.958,19.958,0,1,0,23.131-1.051"
                                      transform="translate(43.714 -82.881)" fill="none"
                                      strokeMiterlimit="10"
                                      strokeWidth="8"/>
                                <line id="Line_2" data-name="Line 2" y2="25.128"
                                      transform="translate(288.467 222.701)"
                                      fill="none" strokeMiterlimit="10" strokeWidth="8"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className=" w-full h-screen flex flex-col items-center lg:items-end justify-end">

                    <img src={bg} alt=""
                         className={'w-[90%] lg:w-[60%]'}/>

                </div>

                <div
                    className="absolute top-0 left-0  w-full lg:w-[40%] bg-primary dark:bg-black z-10 lg:h-screen flex flex-col lg:block items-center lg:pl-[120px] py-3 lg:py-10 lg:pt-[110px]">
                    <div
                        className="uppercase text-xl lg:text-[35px] lg:leading-[45px] uppercase mb-8 text-left text-white border-b-4 py-8 border-white dark:border-primary flex flex-col lg:block lg:w-fit relative after:content[''] after:w-[160%] after:h-[4px] after:bg-white dark:after:bg-primary after:absolute after:top-0 after:left-0">
                        <span className={'font-black text-3xl lg:text-[60px]'}>Hoppá</span> <br/>
                        Sajnos az oldal <br/>
                        nem található
                    </div>
                    <div className="flex">
                        <NavLink to={'/'} className="btn-primary w-fit px-4 mb-8">Főoldal</NavLink>
                    </div>

                </div>
            </div>
        </div>
        <div
            className=" flex md:hidden h-screen overflow-hidden bg-primary dark:bg-black justify-center xl:flex text-center font-bold text-white text-[30px] relative">
            <h2 className={'mt-40 xl:mt-28 relative z-10 xl:text-[60px]'}>A tökéletes játékélmény tableten érhető
                el!</h2>
            <img src={pushing} alt="" className={'absolute left-0 top-1/2 -translate-y-1/2 xl:hidden max-w-[50%]'}/>
            <img src={pushing2} alt=""
                 className={'absolute right-0  top-1/2 -translate-y-1/2 xl:hidden max-w-[50%]'}/>
            <img src={laptop} alt=""
                 className={'absolute right-1/2 translate-x-1/2 bottom-[0] hidden xl:block max-w-[50%]'}/>
        </div>

    </>)
};

export default NotFound;
