import {Routes, Route} from "react-router-dom";
import './App.css';
import Home from "./Pages/Home/Home";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import Login from "./Pages/Login/Login";
import Game from "./Pages/Game/Game";
import ThankYou from "./Pages/ThankYou/ThankYou";
import NotFound from "./Pages/NotFound/NotFound";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-WDNR9HL'
}

function App() {

    TagManager.initialize(tagManagerArgs)
    return (

        <Routes>
            <Route path="/bejelentkezes" element={<Login/>}/>
            <Route path="/" element={<ProtectedRoute/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/game" element={<Game/>}/>
                <Route path="/thank-you" element={<ThankYou/>}/>
            </Route>
            <Route path={'*'} element={<NotFound/>}/>
        </Routes>
    );
}

export default App;
