export const firstPart = [
    {
        id: 1,
        text: 'Az Accord Európa egyik legnagyobb kemoterápiás készítmény szállítója',
    },
    {
        id: 2,
        text: 'Az Accord portfólió több mint 6000 termékből áll olyan terápiás területeken, mint',
    },
    {
        id: 3,
        text: 'Aktívan keressük az innovációs lehetőségeket, hogy',
    },
    {
        id: 4,
        text: 'Az Accord termékei elérhetőek',
    },
    {
        id: 5,
        text: 'Az Accord missziója',
    },
];

export const secondPart = [
    {
        id: 1,
        text: 'Európában minden 3. injekciós onkológiai készítményt az Accord biztosít.*'
    }, {
        id: 2,
        text: 'onkológia, kardiológia, neurológia, pszichiátria, diabetológia, fájdalomcsillapítás és gasztroenterológia.'
    }, {
        id: 3,
        text: 'javítsuk termékválasztékunkat, használatuk biztonságát, kezelésük egyszerűségét, tárolhatóságukat.'
    }, {
        id: 4,
        text: 'az európai lakosság 95%-a számára.'
    },
    {
        id: 5,
        text: 'javítani a betegek hozzáférését minőségi, létfontosságú gyógyszerekhez.'
    },

]
