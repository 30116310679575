import React, {useEffect, useState} from 'react';
import DarkSideButton from "../../Components/UI/DarkSideButton";
import {firstPart, secondPart} from "../../Store/game";
import fig1 from '../../assets/figurak_1.png';
import fig2 from '../../assets/figurak_2.png';
import fig3 from '../../assets/figurak_3.png';
import {NavLink} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import pushing from "../../assets/pushing.png";
import pushing2 from "../../assets/pushing-2.png";
import laptop from "../../assets/laptop.png";


const Game = () => {
    const {logout} = useAuth();
    const [firstParts, setFirstParts] = useState([]);
    const [secondParts, setSecondParts] = useState([]);
    const [itemSelected, setItemSelected] = useState({
        firstItem: null,
        secondItem: null
    })
    const [correctAnswers, setCorrectAnswers] = useState(null);
    const [checking, setChecking] = useState(false);
    const [correctShow, setCorrectShow] = useState(false);
    const [gameFig, setGameFig] = useState(1);

    const figures = {
        1: fig1,
        2: fig2,
        3: fig3
    }

    useEffect(() => {
        setup()
    }, [])

    useEffect(() => {
        if (itemSelected.secondItem !== null) {
            setTimeout(() => {
                let Parts = [...secondParts];
                [Parts[itemSelected.firstItem], Parts[itemSelected.secondItem]] = [Parts[itemSelected.secondItem], Parts[itemSelected.firstItem]];
                setSecondParts(Parts)
                setItemSelected({
                    firstItem: null,
                    secondItem: null
                })
            }, 500)

        }

    }, [itemSelected.secondItem])
    const setup = () => {
        setFirstParts(firstPart.sort(() => .5 - Math.random()));
        setSecondParts(secondPart.sort(() => .5 - Math.random()));
        setItemSelected({
            firstItem: null,
            secondItem: null
        });
        setCorrectAnswers(null);
        setChecking(false);
        setCorrectShow(false);
        setGameFig(Math.floor(Math.random() * 3) + 1)
    }

    const itemSelect = (index) => {
        if (itemSelected.firstItem === null) {
            setItemSelected(prevState => ({
                ...prevState,
                firstItem: index
            }))
        } else {
            if (itemSelected.firstItem === index) {
                setItemSelected(prevState => ({
                    ...prevState,
                    firstItem: null
                }))
            } else {
                setItemSelected(prevState => ({
                    ...prevState,
                    secondItem: index
                }));
            }
        }
    }

    const checkGame = () => {
        const firstPartOrder = firstParts.map(item => item.id);
        const secondPartOrder = secondParts.map(item => item.id);
        const intersection = [...firstPartOrder].filter((element, index) => element === secondPartOrder[index]);

        setCorrectAnswers(intersection);

        setChecking(true)

    }

    const checkArrays = (a, b) => {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    const showCorrect = () => {
        const firstPartOrder = firstParts.map(item => item.id);
        const correctOrder = [...secondParts]
        correctOrder.sort((a, b) => {
            return (
                firstPartOrder.indexOf(a.id) - firstPartOrder.indexOf(b.id)
            );
        })
        setCorrectShow(true);
        setSecondParts(correctOrder);
    }
    return (<>
        <div className="absolute top-5 right-5 flex z-30 gap-3 items-center justify-center">
            <DarkSideButton/>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 47.917 52.198"
                 onClick={logout}
                 className={'stroke-slate-400 dark:stroke-primary'}>
                <g id="Component_27_3" data-name="Component 27 – 3" transform="translate(4)">
                    <g id="Group_32" data-name="Group 32" transform="translate(-269.328 -222.701)">
                        <path id="Path_10" data-name="Path 10" d="M233.27,318.105a19.958,19.958,0,1,0,23.131-1.051"
                              transform="translate(43.714 -82.881)" fill="none" strokeMiterlimit="10"
                              strokeWidth="8"/>
                        <line id="Line_2" data-name="Line 2" y2="25.128" transform="translate(288.467 222.701)"
                              fill="none" strokeMiterlimit="10" strokeWidth="8"/>
                    </g>
                </g>
            </svg>

        </div>
        <div className={'min-h-screen relative z-20 flex flex-col text-center hidden md:flex xl:hidden'}>

            <div className="absolute -z-10"><img src={figures[gameFig]} alt=""/></div>
            <div className={'mt-6'}>
                <h1 className={'text-[30px] text-primary dark:text-white tracking-wide uppercase font-black mb-3'}>Párosítsa
                    össze a
                    mondatrészeket!</h1>
                <p className='uppercase text-primary dark:text-white tracking-widest font-normal text-[1.75vw]'>Klikkeljen
                    és
                    cserélgesse
                    a
                    jobb oldali
                    mondatrészeket
                    egymással, amíg
                    a megfelelő
                    helyükre kerülnek!</p>
                <div className="grid grid-cols-2 gap-x-8 my-2 text-left w-2/3 mx-auto border-y-2 py-6 border-primary">
                    <div className="grid gap-3">  {firstParts.map(item => <div key={item.id}>
                        <div className={' fix-item game-item'}
                             key={'firstPart' + item.id}>{item.text}</div>
                    </div>)}
                    </div>
                    <div className="flex flex-col relative gap-3"> {secondParts.map((item, index) => <div
                        className={` move-item game-item ${itemSelected.firstItem === index && 'border-primary dark:border-primary'} 
                    ${itemSelected.secondItem === index && 'border-primary dark:border-primary'} ${(checking) && (correctAnswers?.includes(item.id) ? 'bg-[#A6CFD7] dark:bg-[#a6cfd7] border-[#77AFBA] dark:border-[#77AFBA]' : 'bg-[#FFD2BE] dark:bg-[#FFD2BE] border-[#FF9380] dark:border-[#FF9380]')} 
                    ${checking && 'text-black dark:text-black pointer-events-none'} ${correctShow && 'bg-primary dark:bg-primary !text-white !border-primary dark:!border-primary'} ${itemSelected.secondItem && 'pointer-events-none'}`}
                        key={'secondPart' + item.id}
                        onClick={() => itemSelect(index)}>{item.text}</div>)}
                        <div className="absolute bottom-0 right-0 translate-y-full pt-2 text-[10px]">*Forrás: IQVIA June
                            2021
                        </div>
                    </div>
                    {!checking ?
                        <div className="btn-secondary  mt-8" onClick={checkGame}>Ellenőrzés</div> : correctShow ? <>
                            <div className="btn-secondary gtm_startbutton mt-8" onClick={setup}>Újrakezdés</div>
                            <NavLink to={'/thank-you'}
                                     className=" text-right mt-8 text-[20px] text-primary dark:text-white gtm_finishbutton"
                            >Befejezés</NavLink>
                        </> : <>
                            <div className="btn-secondary mt-8" onClick={showCorrect}>Megoldás</div>
                            <NavLink to={'/thank-you'}
                                     className=" text-right mt-8 text-[20px] text-primary dark:text-white gtm_finishbutton"
                            >Befejezés</NavLink>
                        </>
                    }
                </div>


            </div>

        </div>
        <div
            className=" flex md:hidden h-screen overflow-hidden bg-primary dark:bg-black justify-center xl:flex text-center font-bold text-white text-[30px] relative">
            <h2 className={'mt-40 xl:mt-28 relative z-10 xl:text-[60px]'}>A tökéletes játékélmény tableten érhető
                el!</h2>
            <img src={pushing} alt="" className={'absolute left-0 top-1/2 -translate-y-1/2 xl:hidden max-w-[50%]'}/>
            <img src={pushing2} alt=""
                 className={'absolute right-0  top-1/2 -translate-y-1/2 xl:hidden max-w-[50%]'}/>
            <img src={laptop} alt=""
                 className={'absolute right-1/2 translate-x-1/2 bottom-[0] hidden xl:block max-w-[50%]'}/>
        </div>
    </>)
};

export default Game;
