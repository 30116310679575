import React, {useState} from 'react';
import DarkSideButton from "../../Components/UI/DarkSideButton";
import video from "../../assets/videos/video.mp4";
import {NavLink} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import pushing from '../../assets/pushing.png'
import pushing2 from '../../assets/pushing-2.png'
import laptop from '../../assets/laptop.png'


const Home = () => {
    const {logout} = useAuth()
    return (<>
            <div className="absolute top-5 right-5 flex md:hidden xl:flex z-30 gap-3 items-center justify-center">
                <DarkSideButton/>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 47.917 52.198"
                     onClick={logout}
                     className={'stroke-slate-400 dark:stroke-primary'}>
                    <g id="Component_27_3" data-name="Component 27 – 3" transform="translate(4)">
                        <g id="Group_32" data-name="Group 32" transform="translate(-269.328 -222.701)">
                            <path id="Path_10" data-name="Path 10" d="M233.27,318.105a19.958,19.958,0,1,0,23.131-1.051"
                                  transform="translate(43.714 -82.881)" fill="none" strokeMiterlimit="10"
                                  strokeWidth="8"/>
                            <line id="Line_2" data-name="Line 2" y2="25.128" transform="translate(288.467 222.701)"
                                  fill="none" strokeMiterlimit="10" strokeWidth="8"/>
                        </g>
                    </g>
                </svg>

            </div>
            <div className={'h-screen flex flex-col text-center hidden md:flex xl:hidden'}>
                <div className={'flex items-center w-full bg-[#b7b7b7] h-full justify-center'}>
                    <div className="relative">
                        <div className="absolute top-5 flex  gap-3 items-center justify-center right-5 ">
                            <DarkSideButton/>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 47.917 52.198"
                                 className={'stroke-slate-400 dark:stroke-primary'}
                                 onClick={logout}>
                                <g id="Component_27_3" data-name="Component 27 – 3" transform="translate(4)">
                                    <g id="Group_32" data-name="Group 32" transform="translate(-269.328 -222.701)">
                                        <path id="Path_10" data-name="Path 10"
                                              d="M233.27,318.105a19.958,19.958,0,1,0,23.131-1.051"
                                              transform="translate(43.714 -82.881)" fill="none"
                                              strokeMiterlimit="10"
                                              strokeWidth="8"/>
                                        <line id="Line_2" data-name="Line 2" y2="25.128"
                                              transform="translate(288.467 222.701)"
                                              fill="none" strokeMiterlimit="10" strokeWidth="8"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <video src={video} autoPlay={true} muted width={'100%'} height={'100%'}></video>
                        <NavLink to={'/game'} className="absolute bottom-[100px] right-[150px] btn-toggle py-4 gtm_startbutton">
                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 76.468 47.116"
                                 className={'svg w-[38px] h-[24px]'}>
                                <g id="Group_33" data-name="Group 33" transform="translate(-31.393 -46.069)">
                                    <path id="Path_7" data-name="Path 7"
                                          d="M165.081,492.246H92.137a1.762,1.762,0,0,1,0-3.524h72.944a1.762,1.762,0,0,1,0,3.524Z"
                                          transform="translate(-58.982 -420.855)"/>
                                    <path id="Path_8" data-name="Path 8"
                                          d="M110.812,527.871a1.762,1.762,0,0,1-1.245-3.007l20.551-20.551-20.551-20.551a1.761,1.761,0,0,1,2.49-2.492l21.8,21.8a1.762,1.762,0,0,1,0,2.49l-21.8,21.8A1.748,1.748,0,0,1,110.812,527.871Z"
                                          transform="translate(-26.563 -434.686)"/>
                                </g>
                            </svg>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div
                className=" flex md:hidden min-h-screen overflow-hidden bg-primary dark:bg-black justify-center xl:flex text-center font-bold text-white text-[30px] relative">
                <h2 className={'mt-40 relative z-10 xl:mt-28 xl:text-[60px]'}>A tökéletes játékélmény tableten érhető
                    el!</h2>
                <img src={pushing} alt="" className={'absolute left-0 top-1/2 -translate-y-1/2 xl:hidden max-w-[50%]'}/>
                <img src={pushing2} alt=""
                     className={'absolute right-0 top-1/2 -translate-y-1/2 xl:hidden max-w-[50%]'}/>
                <img src={laptop} alt=""
                     className={'absolute right-1/2 translate-x-1/2 bottom-[0] hidden xl:block max-w-[50%]'}/>
            </div>

        </>
    )
};

export default Home;
